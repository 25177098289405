<template>
  <svg
    id="exM4Ec1TACX1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 950 960"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
  >
    <path
      id="exM4Ec1TACX2"
      d="M559.725,4.946C563.749,4.849,567.772,5.184,571.725,5.946C578.681,17.787,578.861,47.656,575.725,62.946C573.653,64.229,573.425,65.023,570.725,65.946C567.146,68.171,560.742,65.764,557.725,63.946C551.005,59.898,519.852,43.325,521.725,33.946C523.925,22.912,551.439,12.627,559.725,4.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX3"
      d="M544.725,768.946C570.485,768.398,580.392,782.223,595.725,791.946C623.944,809.841,655.99,825.346,679.725,847.946C689.737,857.477,703.033,875.958,690.725,892.946C682.525,904.257,666.825,906.21,652.725,911.946C625.885,922.868,596.413,933.899,568.725,942.946C555.461,947.28,535.978,948.531,525.725,940.946C515.411,933.317,511.525,906.446,515.725,887.946C520.304,867.783,519.136,844.46,523.725,823.946C526.913,809.694,523.905,797.446,528.725,785.946C532.309,777.398,538.525,774.687,544.725,768.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX4"
      d="M370.725,752.946C378.387,752.807,403.175,751.032,407.725,753.946C419.301,758.046,422.762,775.076,425.725,787.946L425.725,800.946C427.95,811.461,425.238,824.064,427.725,835.946C430.017,846.9,427.225,859.872,429.725,871.946C434.096,893.07,434.39,924.254,424.725,938.946C414.874,953.921,387.57,954.722,368.725,946.946C343.098,936.371,315.036,926.428,289.725,915.946C274.181,909.509,257.532,906.11,244.725,896.946C239.325,893.083,237.17,886.754,232.725,881.946C232.137,852.146,252.878,840.212,271.725,826.946C293.925,811.323,313.975,791.922,335.725,775.946C343.925,769.921,352.263,763.19,360.725,757.946C364.183,755.803,367.935,755.587,370.725,752.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX5"
      d="M737.725,676.946C760.614,676.409,774.659,686.364,788.725,694.946C797.661,700.398,807.425,703.108,816.725,707.946C833.132,716.478,850.741,726.374,867.725,733.946C879.002,738.974,902.789,751.339,898.725,769.946C894.798,787.922,882.497,795.805,869.725,804.946C848.274,820.298,826.693,835.567,804.725,850.946C793.473,858.823,783.051,869.146,767.725,872.946C756.33,875.811,744.298,871.929,736.725,862.946C715.974,838.59,725.497,812.467,717.725,776.946C717.392,768.279,717.058,759.612,716.725,750.946C713.992,738.074,711.986,711.016,715.725,698.946C719.143,687.912,726.409,684.805,733.725,677.946C736.053,677.751,736.551,677.753,737.725,676.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX6"
      d="M158.725,634.946C186.092,634.625,196.368,651.396,211.725,662.946C238.712,683.246,266.165,703.952,291.725,725.946C300.218,733.254,320.317,747.882,314.725,767.946C309.183,787.832,290.052,793.418,271.725,800.946C244.557,812.105,215.359,823.259,187.725,833.946C178.394,837.555,156.02,846.954,142.725,843.946C128.089,840.635,116.017,835.34,110.725,822.946C105.599,810.946,107.682,790.128,110.725,777.946C116.756,753.805,117.864,728.803,123.725,705.946C128.416,687.652,127.225,667.407,135.725,652.946C138.266,648.621,143.607,641.711,147.725,638.946C150.875,636.831,155.525,637.035,158.725,634.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX7"
      d="M644.725,604.946C655.104,604.808,663.846,604.754,669.725,608.946C680.807,616.846,681.291,633.087,684.725,648.946L684.725,669.946C687.232,682.155,683.991,696.505,686.725,709.946C687.992,716.174,686.207,726.446,687.725,733.946C694.086,765.382,683.386,788.139,658.725,792.946C644.459,795.727,631.062,784.503,622.725,779.946C601.205,768.184,580.23,756.488,559.725,743.946C547.674,736.575,534.745,732.173,525.725,721.946C520.025,715.487,513.813,699.546,519.725,687.946C526.257,675.13,540.7,668.521,552.725,660.946C576.025,646.267,599.445,629.599,622.725,614.946C629.186,610.879,638.606,609.704,644.725,604.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX8"
      d="M27.725,604.946C46.025,604.514,60.131,609.884,74.725,613.946C96.692,620.06,109.35,617.016,123.725,630.946C124.116,635.766,125.5,639.211,123.725,643.946C119.525,655.164,108.365,661.197,101.725,669.946C93.619,680.625,84.117,689.582,74.725,698.946C70.535,703.123,58.625,715.907,47.725,708.946C31.239,704.278,22.36,654.86,16.725,636.946C14.765,630.716,13.213,619.01,16.725,613.946C18.139,609.761,21.053,608.717,23.725,605.946C26.053,605.746,26.551,605.753,27.725,604.946Z"
      transform="matrix(1 0 0 1 0 0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX9"
      d="M918.725,593.946C926.171,593.774,933.597,594.787,940.725,596.946C951.278,612.81,945.086,628.519,939.725,644.946C933.374,664.407,931.834,689.646,920.725,704.946C918.077,708.592,913.025,709.925,909.725,712.946C904.28,713.02,897.77,713.598,893.725,711.946C881.925,707.126,872.025,694.611,864.725,684.946L850.725,671.946C846.204,665.946,841.687,660.546,836.725,654.946C831.572,649.126,815.88,635.408,824.725,622.946C828.925,611.852,841.379,612.239,853.725,608.946C868.692,604.954,886.251,601.98,900.725,597.946C906.978,596.203,913.985,597.185,918.725,593.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX10"
      d="M401.725,550.946C411.825,550.81,420.013,550.872,425.725,554.946C436.091,562.34,436.451,577.146,439.725,591.946L439.725,607.946C442.784,622.591,439.466,639.091,442.725,654.946C446.976,675.628,447.966,707.589,437.725,720.946C434.074,725.708,424.548,728.507,417.725,729.946C403.112,733.027,383.976,720.02,374.725,715.946C353.037,706.395,331.764,696.197,310.725,686.946C299.039,681.807,286.677,677.625,279.725,667.946C274.073,660.076,272.069,646.403,276.725,635.946C283.347,621.072,300.649,613.636,313.725,604.946C336.198,590.011,358.725,573.166,381.725,558.946C387.612,555.306,395.996,555.016,401.725,550.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX11"
      d="M230.725,491.946C238.432,491.825,245.166,491.886,249.725,494.946C265.409,505.475,258.434,523.815,262.725,544.946C266.332,562.713,266.712,584.656,266.725,603.946C266.732,615.146,267.252,626.212,262.725,632.946C259.501,637.746,252.201,641.446,245.725,642.946C231.925,646.135,219.898,636.537,211.725,632.946C194.295,625.288,177.508,617.3,160.725,609.946C150.07,605.278,140.365,602.365,132.725,594.946C126.904,589.293,119.297,579.511,123.725,566.946C128.651,552.968,143.207,546.6,154.725,538.946C173.574,526.42,192.458,511.846,211.725,499.946C217.49,496.386,225.142,495.889,230.725,491.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX12"
      d="M720.725,459.946C735.115,459.832,745.715,464.273,754.725,469.946C777.608,484.353,800.267,500.974,822.725,515.946C837.896,526.06,854.056,533.371,858.725,553.946C861.644,566.808,850.959,579.337,844.725,583.946C837.874,589.012,830.114,591.304,821.725,594.946C799.774,604.476,775.45,614.809,753.725,625.946C745.525,630.146,730.159,639.789,716.725,636.946C689.69,631.224,684.851,604.708,691.725,572.946C695.025,557.71,691.625,540.271,694.725,525.946L694.725,509.946C697.317,498.092,695.841,484.394,700.725,474.946C705.431,465.846,712.55,465.624,720.725,459.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX13"
      d="M549.725,453.946C567.346,454.098,576.857,462.266,587.725,468.946C607.698,481.222,628.654,491.213,647.725,503.946C655.891,509.398,671.04,518.146,667.725,533.946C663.777,552.768,647.825,559.105,633.725,567.946C614.778,579.825,595.594,593.125,576.725,604.946C569.81,609.278,550.109,621.204,539.725,613.946C517.31,607.688,524.725,536.515,524.725,505.946C524.725,492.977,523.769,479.154,527.725,469.946C530.338,463.864,537.34,457.182,543.725,454.946C546.103,454.113,548.217,454.902,549.725,453.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX14"
      d="M355.725,408.946C362.035,408.918,368.608,408.56,372.725,410.946C384.425,417.725,390.059,430.994,397.725,441.946C407.893,456.473,420.389,470.908,429.725,485.946C433.969,492.781,439.284,509.027,433.725,518.946C422.283,539.362,364.953,531.746,337.725,537.946C329.561,539.805,312,539.551,305.725,536.946C292.914,531.627,290.925,514.713,297.725,500.946C305.003,486.218,314.153,471.446,321.725,456.946C327.991,444.946,331.987,431.623,339.725,420.946C343.733,415.415,350.325,413.178,355.725,408.946Z"
      transform="matrix(1 0 0 1 0 0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX15"
      d="M915.725,404.946C953.339,404.326,944.725,451.194,944.725,486.946C944.725,505.294,947.277,530.205,933.725,534.946C924.515,543.578,905.099,532.792,897.725,528.946C878.425,518.879,858.694,507.336,840.725,495.946C828.796,488.385,818.279,484.069,818.725,463.946C822.372,460.08,823.418,454.166,827.725,450.946C835.278,445.298,844.352,443.028,852.725,437.946C867.882,428.746,884.637,420.09,899.725,410.946L915.725,404.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX16"
      d="M30.725,398.946C43.038,398.718,51.686,400.771,59.725,404.946C82.38,416.711,104.998,430.974,127.725,442.946C143.812,451.42,162.084,457.229,166.725,476.946C170.682,493.759,151.288,506.262,142.725,511.946C120.981,526.379,97.858,538.424,73.725,550.946C55.925,560.183,46.443,569.205,20.725,565.946C16.795,560.946,11.21,557.614,8.725,550.946C5.097,541.214,6.725,525.602,6.725,512.946C6.725,484.532,1.65,432.176,11.725,412.946C15.148,406.411,21.625,404.71,26.725,399.946C29.053,399.751,29.551,399.753,30.725,398.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX17"
      d="M216.725,353.946C225.98,353.888,231.469,355.158,236.725,358.946C245.811,365.495,257.794,380.767,264.725,389.946C275.33,403.99,293.881,408.889,293.725,433.946C290.85,436.865,289.137,441.635,285.725,443.946C272.562,452.862,251.294,452.979,234.725,457.946C219.925,462.382,203.515,467.832,185.725,463.946C183.708,461.036,179.947,458.423,178.725,454.946C173.425,439.855,188.605,407.394,192.725,394.946C196.464,383.646,197.587,371.819,203.725,362.946C206.812,358.483,212.414,357.262,216.725,353.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX18"
      d="M599.725,335.946C606.059,336.005,610.856,336.359,614.725,338.946C623.189,344.606,624.859,355.39,628.725,365.946C635.144,383.47,642.207,403.479,646.725,421.946C648.725,430.1,645.025,436.875,643.725,441.946C641.247,443.654,638.661,446.954,635.725,447.946C626.825,450.954,615.718,447.064,608.725,444.946C592.525,440.04,576.056,437.646,560.725,432.946C549.463,429.49,536.386,428.701,532.725,417.946C526.434,409.72,535.553,396.657,539.725,391.946L571.725,359.946C576.657,353.438,583.013,344.453,589.725,339.946C592.696,337.951,596.857,337.962,599.725,335.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX19"
      d="M850.725,295.946C869.539,295.626,875.851,304.686,886.725,311.946C902.794,322.675,913.56,328.021,926.725,341.946C926.963,348.446,926.517,349.86,925.725,355.946L916.725,364.946C908.448,370.382,899.054,372.866,889.725,376.946C878.77,381.737,868.508,389.946,855.725,392.946C849.576,394.388,845.867,390.983,841.725,389.946C834.089,377.459,836.073,349.631,839.725,332.946C841.983,322.63,838.946,312.281,842.725,303.946L850.725,295.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX20"
      d="M777.725,293.946C789.025,293.585,798.035,296.787,800.725,304.946C807.955,314.068,801.813,337.335,800.725,348.946C797.887,379.246,796.04,414.686,781.725,433.946C775.262,442.619,763.794,445.896,753.725,441.946C746.002,439.039,740.767,432.437,734.725,427.946C722.075,418.546,710.132,408.081,697.725,398.946C687.298,391.269,673.547,384.046,666.725,372.946C660.957,363.558,663.015,348.865,668.725,340.946C682.965,321.196,728.282,310.346,753.725,300.946L777.725,293.946Z"
      opacity="1"
      fill="rgb(251,133,0)"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX21"
      d="M407.725,274.946C419.825,274.735,428.055,276.982,432.725,283.946C438.032,291.86,436.734,306.802,436.725,319.946C436.713,338.867,438.507,383.397,430.725,394.946C428.698,397.954,424.351,399.462,421.725,401.946C399.725,403.187,388.165,391.832,373.725,382.946C358.135,373.352,341.365,366.605,326.725,355.946C319.413,350.622,311.502,338.841,316.725,326.946C321.351,316.41,333.146,313.746,342.725,307.946C357.672,298.893,374.03,290.099,389.725,281.946C395.16,279.123,402.47,278.459,407.725,274.946Z"
      transform="matrix(1 0 0 1 1.364425 -0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX22"
      d="M90.725,255.946C96.563,255.968,102.059,255.673,105.725,257.946C114.225,263.218,117.425,273.273,122.725,281.946C130.699,294.989,138.425,308.546,146.725,321.946C150.025,327.274,155.218,332.958,157.725,338.946C160.338,345.186,159.54,353.764,158.725,360.946C154.703,364.162,152.082,368.957,146.725,370.946C137.348,374.428,122.879,372.946,110.725,372.946C83.051,372.946,43.582,379.923,33.725,359.946C27.561,347.452,38.059,334.559,41.725,327.946C53.356,306.96,65.153,281.329,79.725,262.946C82.31,259.684,87.419,258.546,90.725,255.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX23"
      d="M239.725,250.946C244.199,250.816,247.404,251.057,249.725,252.946C258.334,255.861,259.782,264.332,263.725,271.946C272.194,288.301,285.181,304.686,283.725,326.946C280.554,329.404,277.789,333.433,273.725,334.946C257.597,340.946,208.545,331.388,195.725,327.946C194.643,325.789,193.3,323.774,191.725,321.946C191.584,305.021,214.317,273.471,222.725,261.946C225.92,257.567,231.791,255.625,235.725,251.946C238.053,251.751,238.551,251.753,239.725,250.946Z"
      transform="matrix(1 0 0 1 1.364425 -0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX24"
      d="M682.725,210.946L696.725,211.946C704.181,214.446,741.471,236.797,744.725,241.946C747.21,245.878,746.119,249.079,745.725,253.946C728.585,265.255,704.246,291.799,677.725,285.946C676.11,282.794,673.671,279.677,672.725,275.946L672.725,220.946C674.46,216.002,679.299,214.214,682.725,210.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX25"
      d="M541.725,206.946C558.531,206.613,575.317,208.292,591.725,211.946C603.147,214.521,614.925,211.871,624.725,215.946C630.399,218.304,633.645,223.016,637.725,226.946C639.892,253.637,615.925,269.391,603.725,285.946C598.243,293.387,593.019,304.446,585.725,309.946C580.025,314.246,575.185,316.099,564.725,315.946C561.77,313.022,557.175,311.33,554.725,307.946C546.109,296.046,542.777,278.917,536.725,264.946C532.102,254.274,525.41,243.399,522.725,230.946C521.602,225.737,524.211,222.319,524.725,217.946L532.725,209.946L541.725,206.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX26"
      d="M810.725,170.946L821.725,171.946C823.847,174.718,827.713,176.17,829.725,178.946C834.978,186.195,837.471,194.771,841.725,202.946C846.478,212.078,852.584,222.084,857.725,230.946C860.948,236.501,865.058,249.605,860.725,255.946C857.525,265.165,845.137,263.946,832.725,263.946C818.271,263.946,787.671,265.208,777.725,259.946C774.696,258.346,773.086,254.308,770.725,251.946C768.914,230.751,781.916,214.397,790.725,199.946C794.847,193.184,796.175,185.225,800.725,178.946C803.164,175.58,807.702,173.784,810.725,170.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX27"
      d="M370.725,170.946C393.913,170.729,406.841,194.382,420.725,204.946C425.425,208.523,435.614,215.746,431.725,226.946C428.769,235.463,417.703,236.079,408.725,238.946C394.325,243.546,380.175,250.336,362.725,248.946C361.061,246.746,359.889,246.92,358.725,243.946C356.153,241.221,354.251,233.261,355.725,226.946C360.025,208.535,359.473,182.625,370.725,170.946Z"
      transform="matrix(1 0 0 1 1.364425 -0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX28"
      d="M301.725,162.946C307.126,162.847,312.514,163.52,317.725,164.946C326.403,178.124,322.747,193.532,322.725,212.946C322.712,223.961,323.78,234.953,315.725,237.946C310.156,242.358,299.695,238.525,294.725,235.946C278.835,227.698,262.104,217.011,249.725,204.946L249.725,195.946C260.853,187.657,272.457,178.476,284.725,170.946C289.984,167.718,296.93,166.771,301.725,162.946Z"
      transform="matrix(1 0 0 1 1.364425 -0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX29"
      d="M176.725,143.946C182.113,145.854,186.872,146.693,189.725,150.946C194.525,158.099,193.325,168.278,195.725,177.946C200.131,195.684,205.594,216.202,200.725,234.946C197.195,237.284,193.719,239.898,188.725,240.946C181.173,242.531,171.325,237.646,165.725,235.946C144.453,229.486,126.846,229.675,112.725,215.946C112.635,196.466,123.211,191.475,132.725,181.946C142.696,171.959,151.278,158.221,162.725,149.946C166.782,147.013,172.325,146.846,176.725,143.946Z"
      transform="matrix(1 0 0 1 0.000001 0.089394)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX30"
      d="M645.725,119.946C654.625,119.51,661.525,119.464,663.725,125.946C667.232,130.332,665.444,140.267,663.725,145.946C658.025,164.772,657.04,184.375,644.725,196.946C623.646,197.189,596.997,167.846,585.725,155.946C585.689,150.403,586.745,147.973,587.725,143.946C597.639,137.813,609.508,132.471,621.725,127.946C629.574,125.039,639.402,124.619,645.725,119.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX31"
      d="M720.725,95.946C740.045,95.833,762.719,124.996,773.725,135.946C774.045,141.06,775.375,144.818,772.725,148.946C768.542,155.462,759.368,156.671,751.725,159.946C739.008,165.395,727.025,174.801,709.725,174.946C707.225,172.516,704.251,170.292,701.725,167.946C700.91,154.915,707.833,110.695,713.725,101.946L720.725,95.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX32"
      d="M410.725,88.946L420.725,89.946C423.043,93.046,426.552,95.697,427.725,99.946C430.557,110.203,423.803,148.303,421.725,156.946C418.051,159.327,417.284,160.831,410.725,160.946C398.254,151.288,362.113,133.594,361.725,114.946C371.45,104.046,387.473,98.081,401.725,91.946C404.985,90.546,408.155,90.846,410.725,88.946Z"
      transform="matrix(1 0 0 1 1.364425 0)"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX33"
      d="M533.725,82.946C548.601,82.698,559.041,91.271,569.725,95.946C576.744,99.017,585.169,99.513,587.725,106.946C589.16,108.9,589.125,113.992,587.725,115.946C585.449,122.96,580.042,125.013,574.725,128.946C563.488,137.257,552.304,154.146,534.725,154.946C532.225,152.568,529.174,151.424,527.725,147.946C526.058,130.612,524.392,113.279,522.725,95.946C523.889,91.182,526.145,86.573,530.725,84.946C531.496,84.264,532.957,83.635,533.725,82.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX34"
      d="M237.725,71.946C243.17,72.046,247.256,72.699,250.725,74.946C256.389,78.614,260.65,85.546,264.725,90.946C273.67,102.798,289.551,109.446,291.725,127.946C290.679,129.434,289.995,131.146,289.725,132.946C276.634,141.36,253.616,141.112,235.725,144.946C227.309,146.746,219.046,143.673,216.725,138.946C212.851,134.768,213.655,126.223,215.725,119.946C221.371,102.828,225.71,83.089,237.725,71.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX35"
      d="M615.725,24.946C634.15,24.466,644.763,36.146,657.725,41.946C663.772,44.653,669.525,45.496,671.725,51.946C673.062,53.938,672.825,58.453,672.725,61.946C661.355,70.516,642.487,88.307,624.725,88.946C621.625,86.405,619.233,86.3,617.725,81.946C612.796,75.56,609.337,35.476,612.725,26.946C613.829,26.449,614.842,25.773,615.725,24.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX36"
      d="M325.725,24.946L335.725,24.946C337.955,28.281,339.413,28.141,339.725,33.946C341.048,36.027,340.688,42.923,339.725,46.946C337.132,57.778,338.478,83.179,328.725,86.946C325.31,89.946,318.746,89.001,314.725,86.946C308.1,83.559,281.685,68.451,278.725,62.946C278.392,59.612,278.058,56.279,277.725,52.946C281.831,41.1,315.152,33.078,325.725,24.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
    <path
      id="exM4Ec1TACX37"
      d="M374.725,8.946C385.676,8.583,417.386,15.783,423.725,19.946C427.464,22.401,429.591,26.681,429.725,32.946C427.792,34.946,427.477,37.646,425.725,39.946L420.725,42.946L401.725,62.946C399.505,64.605,397.561,65.022,395.725,66.946C392.033,67.019,388.343,66.683,384.725,65.946C377.78,51.823,366.466,28.618,370.725,10.946C372.97,10.359,373.38,10.198,374.725,8.946Z"
      opacity="1"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    />
  </svg>
</template>

<style>
.cls-1 {
  fill-rule: evenodd;
}
</style>
